<template>
  <div>
    <b-col cols="12">
      <h2>Маршруты</h2>
    </b-col>
    <b-col cols="12" class="mb-4 d-flex justify-content-end">
      <b-button variant="primary" @click="$refs.createModal.show()">Добавить маршрут</b-button>
    </b-col>
    <create ref="createModal"/>
    <update ref="updateModal"/>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="routes" :fields="fields" :small="true">
            <template #cell(actions)="data">
              <div class="text-center">
                <b-icon class="action-icon" icon="pencil-fill" variant="primary" font-scale="1.2" @click="update(data.item)" title="Редактировать"></b-icon>
                <b-icon class="action-icon" icon="trash-fill" variant="danger" font-scale="1.2" @click="remove(data.item.id)" title="Удалить"></b-icon>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";

import Create from './Routes/Create'
import Update from './Routes/Update'

export default {
  name: "Routes",
  data() {
    return {
      formModal: false,
      form: {

      },
      fields: [
        {
          key: 'number',
          label: 'Номер'
        },
        {
          key: 'name',
          label: 'Маршрут'
        },
        {
          key: 'distance',
          label: 'Дистанция',
          formatter: value => {
            const km = Math.floor(value / 1000)
            const m = value % 1000
            return (km ? km + ' км ' : '') + (m ? m + ' м ' : '')
          }
        },
        {
          key: 'time',
          label: 'Время',
          formatter: value => {
            let duration = moment.duration(value, 'minutes');
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();
            return (days ? days + ' д ' : '') + (hours ? hours + ' ч ' : '') + minutes + ' м';
          }
        },
        {
          key: 'actions',
          label: 'Действия'
        }
      ],
    }
  },
  mounted() {
    this.$store.dispatch('Routes/load', { page: 1 })
  },
  computed: {
    ...mapState({
      loading: state => state.Routes.loading,
      routes: state => state.Routes.items
    })
  },
  methods: {
    remove(id) {
      if (!id)
        return

      if (!confirm('Вы действительно хотите удалить Маршрут?'))
        return

      this.$store.dispatch('App/startLoad')
      this.$store.dispatch('Routes/remove', id)
          .then(() => {
            this.$bvToast.toast('Водитель был успешно удален.', {
              title: 'Успех',
              autoHideDelay: 5000,
              variant: 'success',
              appendToast: true
            })
          })
          .finally(() => {
            this.$store.dispatch('App/stopLoad')
          })
    },
    update(form) {
      this.$refs.updateModal.show(form)
    }
  },
  components: {
    Create,
    Update
  }
}
</script>

<style scoped>
.action-icon {
  margin: 4px;
  cursor: pointer;
}
</style>