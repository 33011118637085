<template>
  <b-form>
    <b-row>
      <b-col md="4">
        <b-form-group label="Номер">
          <b-form-input v-model="form.number" placeholder="Номер" :state="formErrors.number"/>
        </b-form-group>
        <b-form-group label="Название">
          <b-form-input v-model="form.name" placeholder="Название" :state="formErrors.name"/>
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-button @click="add" variant="success" size="small" class="mb-4">Добавить пункт</b-button>
        <draggable
            v-model="form.points"
            class="list-group"
            ghost-class="ghost"
        >
          <div v-for="(element, index) in form.points" :key="index" class="list-group-item">
            <div>
              <p><b>{{index + 1}}.<template v-if="index === 0"> Начало пути</template><template v-else-if="form.points.length === index + 1"> Конечная остановка</template></b></p>
              <div>
                <b-row v-if="form.points.length === index + 1">
                  <b-form-input v-model="element.name" placeholder="Название пункта" />
                </b-row>
                <b-row v-else>
                  <b-form-input v-model="element.name" placeholder="Название пункта" class="mb-2"/>
                    Время остановки (минуты):
                    <b-form-input v-model="element.stopTime" placeholder="Время остановки (минуты)" class="mb-2"/>
                  <b-form-group label="Расстояние до следующего пункта" class="mb-0 w-100">
                    <b-row>
                      <b-col md="6">
                        <span>
                          километры:
                          <b-form-input v-model="element.distanceToNextPointKm" placeholder="километры" class="mb-2"/>
                        </span>
                      </b-col>
                      <b-col md="6">
                        <span>
                          метры:
                          <b-form-input v-model="element.distanceToNextPoint" placeholder="метры" class="mb-2"/>
                        </span>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group label="Время в пути до следующего пункта" class="mb-0 w-100">
                    <b-row>
                      <b-col md="6">
                        <span>
                          часы:
                          <b-form-input v-model="element.timeToNextPointHours" placeholder="часы" />
                        </span>
                      </b-col>
                      <b-col md="6">
                        <span>
                          минуты:
                          <b-form-input v-model="element.timeToNextPoint" placeholder="минуты" />
                        </span>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-row>
              </div>
              <b-icon class="action-icon m-1 remove-icon-style" icon="x-lg" @click="removeAt(index)"></b-icon>
            </div>
          </div>
        </draggable>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import draggable from "vuedraggable";
import validate from "@/utils/formValidator";
import ROUTE from "@/forms/ROUTE";

const ROUTE_INIT_FORM = () => ({
  number: '',
  name: '',
  points: []
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => ROUTE_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      form: this.init,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    removeAt(idx) {
      this.form.points.splice(idx, 1);
    },
    add() {
      this.form.points.push({
        name: '',
        stopTime: '',
        distanceToNextPoint: '',
        distanceToNextPointKm: '',
        timeToNextPoint: '',
        timeToNextPointHours: ''
      })
    },
    fillForm(form) {
        const enabledKeys = Object.keys(ROUTE_INIT_FORM())
        this.form = Object.fromEntries(Object.entries(form).filter(([key]) => ['id', ...enabledKeys].includes(key)));
    },
    phoneFormat(value) {
      if (value === '') {
        return ''
      }

      let replacedValue = value.replace(/\D/g, '')
      return ('+' + replacedValue).replace(/\+(\d{1,3})(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1($2)$3-$4-$5')
    },
    resetForm() {
      Object.assign(this.form, ROUTE_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, ROUTE)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      console.log(this.errors)
      if (!this.validateForm())
        return

      this.$emit('onSave', {...this.form, points: this.form.points.map((item, index) => ({...item, order: index + 1 }))});
    }
  },
  components: {
    draggable
  }
}
</script>

<style scoped>
  .remove-icon-style {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
</style>